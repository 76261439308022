<!--Author: zjy   
	Date(该日期为最后修改日期): 2021.01.19
	Description:面包屑组件
	History: 暂无
-->
<template>
	<div>
		<span v-for="(item,index) in breadList" :key="index"><span class="span_a" @click="gomatchpage(index)">{{item.meta.title}}</span><span v-if="index+1 != breadList.length">></span></span>
		<!-- <slot name="item__title"></slot> -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			breadList: [] // 路由集合
		};
	},
	watch: {
		$route() {
			this.getBreadcrumb();
		}
	},
	methods: {
		isProduct(route){
			return route.name === "product"
		},

		getBreadcrumb() {
			//matched返回一个数组，包含当前路由的所有嵌套路径片段的路由记录。
			let matched = this.$route.matched;
			if (!this.isProduct(matched[0])){
				if(matched[0].path == "/prodcut/productdetail"){
				    matched = [{ path: "/product", meta: { title: "产品中心" } }].concat(matched);
				}
				else if(matched[0].path == '/news/newsdetail'){
				    matched = [{ path: "/news", meta: { title: "新闻动态" } }].concat(matched);
				}
			}
			this.breadList = matched;
		},
		gomatchpage(index){
			if(index == 0){
				this.$router.push({
					path:this.breadList[0].path
				})

			}else{
				this.$router.go(0)
			}
		}
	},
	created() {
		this.getBreadcrumb();
	}
}
</script>

<style lang="scss" scoped>
	.span_a{
		&:hover{
			color:#3a86ff;
			text-decoration: underline;
			cursor: pointer;
		}
	}
</style>
