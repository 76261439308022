<template>
	<img class="imgheader" src="@/assets/images/home/advertising-sip-4.png" alt="找不到资源">
</template>

<script>
export default{
	name:'detailheader'
}
</script>

<style>
.imgheader{
	/* width: 1920px; */
	/* height: 200px; */
	max-width: 100%;
	/* max-height: 200px; */
	max-height: 100%;
}
</style>
