
<template>
  <div id="newsdetail">
    <div class="newsdetail_in">
      <div class="top">
        <detailheader></detailheader>
        <div class="breadcrumb">
          <!-- 新闻动态>新闻详情 -->
          <bread-crumb>
          </bread-crumb>
        </div>
      </div>
      <div class="container">
        <div class="article_title">
          <h2>{{ Article.ArticleTitle }}</h2>
          <div class="article_show_info">
            <span>浏览次数：{{ Article.ShowViewTimes }}</span>
            <span class="release_time"
              >发布时间：{{ Article.PublishTimeDisplay }}</span
            >
          </div>
        </div>
        <div class="article_content" v-html="Article.Context"></div>
        <div class="article_bottom" ref="honor_span">
          <span @click="showHonor()">
            <img :src="honorpic" alt="找不到资源" />
            {{ Article.ShowHonorTimes }}
          </span>
          <span @click="showCollection" ref="store_span">
            <img :src="storepic" alt="找不到资源" />
            {{ storefont }}
          </span>
        </div>
      </div>
      <div class="line"></div>
      <div class="changeNews"></div>
      <div class="line"></div>
      <div class="related_news">
        <div class="related_news_title">相关新闻</div>
        <el-collapse-transition>
        <div class="related_news_info">
          <div
            class="related_news_info_single"
            v-for="(item, index) in recomandarticles"
            :key="index"
            @click="toNewsDetail(item)"
          >
            <div>
              <img
                :src="imgUrlFront + item.CoverImgUrl[0]"
                @error="defImg($event, index)"
              />
            </div>
            <div class="news_single_info">
              <div class="news_title">
                {{ item.ArticleTitle }}
              </div>
              <div class="news_intro">
                <i
                  v-for="(it, ids) in item.Tags.split(',').slice(0, 3)"
                  :key="ids"
                >
                  {{ it }}
                </i>
              </div>
            </div>
          </div>
        </div>
        </el-collapse-transition>
      </div>
      <footer-page></footer-page>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/common/breadcrumb/BreadCrumb.vue";
import footerPage from "@/components/common/footer/footerPage.vue";
import detailheader from "@/components/content/detail/detailheader.vue";
import { setSession, getSession } from "@/utils/utils.js";
export default {
  name: "NewsDetail",
  data() {
    return {
      recomandarticles: [],
      Article: "", //当前文章对象
      JZToken: "", //用户token
      UserGuid: "", //用户guid
      // imgUrlFront: "", //图片前缀
      honorpic: require("@/assets/images/detail/like.png"),
      storepic: require("@/assets/images/detail/collection.png"),
      storefont: "收藏",  //收藏-取消收藏
      show:true
    };
  },
  components: {
    footerPage,
    BreadCrumb,
    detailheader,
  },
  computed: {
    //用户信息
    userinfo() {
      return this.$store.state.userinfo;
    },
    //图片前缀
    imgUrlFront(){
      return window.sessionStorage.getItem("apiImgUrl");
    }
  },
  beforeCreate() {
    window.sessionStorage.setItem("isActive_index", 2);
    this.$store.commit(
      "updateIsActiveIndex",
      window.sessionStorage.getItem("isActive_index")
    );
  },
  created() {
    this.init();
  },
  methods: {
    //初始化信息
    init() {
      let flnkid = this.$route.query.newsflnkid
      if (this.userinfo != null || this.userinfo != undefined) {
        this.JZToken = this.userinfo.TokenCode;
        this.UserGuid = this.userinfo.UserGuid;
      }
      let requestParams = {
        Version: this.Version,
        Status: "3",
        IsShowIndex: "0",
        ArticleTypeID: "",
        FLnkID: flnkid,
        JZToken: this.JZToken,
        UserGuid: this.UserGuid,
      };
      //通过页面跳转过来得FLnkID获取文章详情
      this.$axios("API/Get_SingleArticle", requestParams).then((res) => {
        this.Article = res.data;
        this.Article.ArticleTitle = decodeURIComponent(
          this.Article.ArticleTitle
        );
        this.Article.Context = decodeURIComponent(this.Article.Context);
        this.Article.Context = this.Article.Context.replace(
          /\<img/g,
          '<img style="width:100%;height:auto;display:block;"'
        );
        
        
        if (res.data.HonorGuid != null) {
          this.honorpic = require("@/assets/images/detail/like_selected.png");
        }
        if (res.data.StoreGuid != null) {
          this.storepic = require("@/assets/images/detail/collection_selected.png");
          this.storefont = "取消收藏";
        }
        this.getrandomarticle(this.Article.Tags);
      });
    },
    //相关文章
    getrandomarticle(tags) {
      let params = {
        Version: "",
        Tags: tags,
        FLnkID: this.Article.FLnkID,
        Num: 6,
      };
      this.$axios("API/GetRandomArticle", params).then((res) => {
        this.recomandarticles = [];
        res.data.forEach((item) => {
          item.CoverImgUrl = decodeURIComponent(item.CoverImgUrl).split(",");
          item.ArticleTitle = decodeURIComponent(item.ArticleTitle);

          this.recomandarticles.push(item);
        });
      });
    },
    //点赞
    showHonor() {
        let requestParams = {
          Version: "",
          Status: "3",
          IsShowIndex: "0",
          ArticleTypeID: this.Article.ArticleTypeID,
          No: this.Article.No,
          FLnkID: this.Article.FLnkID,
          JZToken: this.JZToken,
          UserGuid: this.UserGuid,
        };
        this.$axios("API/Get_SingleArticle", requestParams).then((res) => {
          this.Article = res.data;
          this.Article.ArticleTitle = decodeURIComponent(
            this.Article.ArticleTitle
          );
          this.Article.Context = decodeURIComponent(this.Article.Context);
          this.Article.Context = this.Article.Context.replace(
            /\<img/g,
            '<img style="width:100%;height:auto;display:block;"'
          );
          this.$axios("API/Up_HonorArticle", 
            {
              Version: "",
              FLnkID: this.Article.FLnkID,
              JZToken: this.JZToken,
              UserGuid: this.UserGuid,
              HonorGuid: this.Article.HonorGuid,
            }
          )
            .then((res) => {
              if(res.status == 200){
                if (this.Article.HonorGuid == null) {
                  this.$message.success({
                    message: "点赞成功",
                    type: "success",
                  });
                  this.honorpic = require("@/assets/images/detail/like_selected.png");
                  this.Article.ShowHonorTimes += 1;
                } else {
                  this.honorpic = require("@/assets/images/detail/like.png");
                  this.Article.ShowHonorTimes -= 1;
                }
              }else if(res.status == 201){
                this.tipinfo()
              }
            
          })
        });
    },
    //提示信息
    tipinfo(){
      this.$confirm('未登录或登录状态失效', '提示', {
        confirmButtonText: '去登录',
        cancelButtonText: '过会再去',
        type: 'warning'
      })
        .then(() => {
        this.$router.push('/login')
        })
    },
    //转发
    //this.$axios('API/Up_ShareArticle',{	Version:'',FLnkID:this.$route.query.FLnkID,JZTkoen:'',UserGuid:''})
    //收藏
    showCollection() {
        this.$axios("API/Get_SingleArticle", {
          Version: "",
          Status: "3",
          IsShowIndex: "0",
          ArticleTypeID: this.Article.ArticleTypeID,
          No: this.Article.No,
          FLnkID: this.Article.FLnkID,
          JZToken: this.JZToken,
          UserGuid: this.UserGuid,
        })
          .then((res) => {
            this.Article = res.data;
            this.Article.ArticleTitle = decodeURIComponent(this.Article.ArticleTitle);
            this.Article.Context = decodeURIComponent(this.Article.Context);
            this.Article.Context = this.Article.Context.replace(
              /\<img/g,
              '<img style="width:100%;height:auto;display:block;"'
            );
            this.$axios("API/Up_StoreArticle",{
                Version: "",
                FLnkID: this.Article.FLnkID,
                JZToken: this.JZToken,
                UserGuid: this.UserGuid,
                StoreGuid: this.Article.StoreGuid,
            })
              .then((res) => {
                if(res.status == 200){
                  if (this.Article.StoreGuid == null) {
                    this.$message.success({
                      message: "收藏成功",
                      type: "success",
                    });
                    this.storepic = require("@/assets/images/detail/collection_selected.png");
                    this.storefont = "取消收藏";
                  } else {
                    this.storepic = require("@/assets/images/detail/collection.png");
                    this.storefont = "收藏";
                  }
                }else if(res.status == 201){
                  this.tipinfo()
                }
              })
          });
    },
    // 默认图片
    defImg(item, index) {
      let img = item.srcElement;
      switch (this.recomandarticles[index].ArticleTypeID) {
        case "1":
          img.src = require("@/assets/images/news/zxzc.png");
          break;
        case "2":
          img.src = require("@/assets/images/news/hyxw.png");
          break;
        case "3":
          img.src = require("@/assets/images/news/qyzz.png");
          break;
        case "4":
          img.src = require("@/assets/images/news/jjjl.png");
          break;
        default:
          img.src = require("@/assets/images/news/zscq.png");
          break;
      }
      img.onerror = null; //防止闪图
    },
    //路由跳转至新闻详情页
    toNewsDetail(item) {
      this.$router.push({
        name: "newsdetail",
        query: {
          newsflnkid: item.FLnkID,
        },
      });
      this.init(item.FLnkID);
    },
  },
};
</script>

<style lang="scss" scoped>
  @media(min-width:1201px){
    @import '@/pages/detail/NewsDetail.scss';
  }
  @media(max-width: 1200px){
    @import '@/pages/detail/NewsDetail_phone.scss';
  }
</style>
